html {
    background: rgba(0, 0, 0, 0.72);
    overflow-x: hidden;
}

svg {
    height: 40px;
}

.background-image {
    opacity: 0.5;
    width: 100vw;
    position: fixed;
    z-index: -1;
    animation: pulse 80s infinite;
}

@keyframes pulse {
    50% {
        scale: 1.5;
    }
}

.hide {
    display: none;
}

.toggle-btn {
    margin-bottom: 10px;
}

#root {
    height: 0px;
}

#home-icon {
    scale: 0.8;
    margin-right: 10px;
}

#home-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#home-container-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#icon-container {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

#icon-container > * {
    margin: 1.5rem;
    scale: 1.4;
    cursor: pointer;
    animation-name: blob;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    opacity: 0.7;
}

#icon-container > *:hover {
    animation: none;
    scale: 2;
    opacity: 1;
}

@keyframes blob {
    0% {
        scale: 1.4;
    }
    50% {
        scale: 1.6;
    }

    100% {
        scale: 1.4;
    }
}

#prof-pic {
    border-radius: 200px;
}

#prof-card {
    padding: 1rem;
    margin-left: 6vw;
    margin-top: 5rem;
    opacity: 0.8;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.562);
    transition: 0.3s;
    max-height: 33rem;
    max-width: 50vw;
}

#prof-card:hover {
    scale: 1.01;
    opacity: 0.95;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.489);
}

#desc {
    max-height: 14rem;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#desc-card {
    margin-top: 5rem;
    width: 30vw;
    opacity: 0.8;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.453);
    transition: 0.3s;
    max-height: 30rem;
    overflow-y: scroll;
    padding: 5px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#desc-card::-webkit-scrollbar {
    display: none;
}

#desc::-webkit-scrollbar {
    display: none;
}

#desc-card:hover {
    scale: 1.01;
    opacity: 0.95;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.455);
}

#nav-burger {
    display: none;
}

#offcanvasDarkNavbar {
    width: 60vw;
}

.card-text {
    color: rgb(255, 255, 255);
}

.fa-brands {
    height: 30px;
    width: 30px;
}

#project-container {
    width: 70vw;
    padding-top: 5vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

#toggle-caption-btn {
    margin-top: 8px;
}

.carousel-caption {
    background-color: rgba(0, 0, 0, 0.61);
    transition: 0.4s;
    border-radius: 100px;
    padding-left: 30px;
    padding-right: 30px;
    max-height: 200px;
}

.carousel-caption:hover {
    scale: 1.05;
    opacity: 0;
}

.carousel-item > img {
    opacity: 0.9;
}

.carousel-link {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
}

#contact-container {
    background-color: rgba(0, 0, 0, 0.192);
    padding: 100px;
    color: white;
    border-radius: 40px;
}

#contact-container-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

#resume-container-1 {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#resume-container-1 > * {
    margin-top: 20px;
}

#resume-img {
    object-fit: contain;
    width: 60vw;
    opacity: 0.9;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.359);
    transition: 0.2s;
}

#resume-img:hover {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.524);
    scale: 1.02;
}

.resume-btn-div {
    display: flex;
}

.resume-btn-div > * {
    margin: 0.5rem;
}

.download-div {
    background-color: rgba(0, 0, 0, 0.439);
    border-radius: 100px;
    padding: 20px;
    color: white;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@media screen and (max-width: 700px) {
    #nav-burger {
        display: block;
    }

    #nav-options {
        display: none;
    }

    #home-container {
        flex-direction: column-reverse;
    }

    #prof-card {
        padding: 1rem;
        margin-top: 5rem;
        opacity: 0.8;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.562);
        transition: 0.3s;
        max-height: 33rem;
        max-width: 100vw;
        margin-left: 0;
    }

    #prof-card:hover {
        scale: 1;
        opacity: 0.95;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.562);
    }

    #desc-card {
        margin-top: 5rem;
        width: 90vw;
        opacity: 0.9;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.453);
        transition: 0.3s;
        max-height: 30rem;
        overflow-y: scroll;
        padding: 5px;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    #desc-card:hover {
        scale: 1;
        opacity: 0.9;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.453);
    }

    #icon-container > * {
        animation: none;
    }

    #icon-container > *:hover {
        animation: none;
        scale: 1.4;
        opacity: 0.7;
    }

    #project-container {
        width: 95vw;
        padding-top: 12vh;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100vh;
    }

    .carousel-caption {
        display: block;
        align-self: center;
        width: 70%;
        height: 100px;
        overflow-y: scroll;
    }

    .carousel-caption:hover {
        scale: 1;
        background-color: rgba(0, 0, 0, 0.61);
        opacity: 1;
    }
}
